import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import { graphql, useStaticQuery } from 'gatsby';
import styles from '../components/subpage.module.scss';
import Template from '../components/subpageTemplate';
import PhoneNumberInfo from '../components/phoneNumberInfo';

const Serwis = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { shortcut: { eq: "serwis" } }) {
        frontmatter {
          title
        }
        html
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Serwis" />
      <div className={styles.container}>
        <h2>{data.markdownRemark.frontmatter.title}</h2>
        <Template data={data} />
        <PhoneNumberInfo />
      </div>
    </Layout>
  );
};

export default Serwis;
